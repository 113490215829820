









































import Vue from "vue"
import Component from "vue-class-component"
import { StoreMutation } from "lib/types/vuex"

import { namespace, State } from "vuex-class"

import { CustomerState } from "store/customer/types"
import { customer as customerApi } from "api/gateway"

import LabeledInput from "components/form/LabeledInput.vue"
import ActionButton from "components/button/ActionButton.vue"
import ProtectedButton from "leven-shared-components/ProtectedButton.vue"

const routing = namespace("routing")
const customer = namespace("customer")

@Component({
	components: {
		LabeledInput,
		ActionButton,
		ProtectedButton
	}
})
export default class CommunicationPreferenceModal extends Vue {

	@State customer!: CustomerState
	@State axonImpersonation!: boolean

	@customer.Mutation setOnlineCommunication!: StoreMutation<boolean | null>
	@routing.State changeCommunicationPreferenceClicked!: boolean
	@routing.Mutation initChangeCommunicationPreference!: StoreMutation<boolean>
	@routing.Mutation setCommunicationPreferenceAcquired!: StoreMutation<boolean>

	visible: boolean = false
	loaded: boolean = false

	modalTitle: any | null = null

	communicationDataSaved = false
	basicData?: any = null
	communicationData?: any = null
	onlineCommunication: boolean = true
	emailCompare: string | null = null

	submitted: boolean = false
	savedChangesMessage: string = "Uw wijzigingen zijn verzonden naar a.s.r."

	selectedItem: string = "Communicatievoorkeur"

	get changedEmail() {
		return this.emailCompare !== this.basicData.emailAddress1
	}

	async mounted() {
		// FIXME: timeout necessary to prevent the modal to appear briefly before transitioning.
		setTimeout(() => this.loaded = true, 200)

		await this.getCommunicationDataModal()
		this.display()
	}

	async getCommunicationDataModal() {
		await Vue.nextTick()

		if (this.customer.externalIdentifier) {
			this.basicData = await customerApi.getCustomerBasicdata(this.customer.externalIdentifier)
			this.communicationData = await customerApi.getCommunicationPreferences(this.customer.externalIdentifier)

			this.modalTitle = this.customer.previousLogin === undefined ? "Welkom in uw a.s.r. account" : "Welkom terug in uw a.s.r. account"
			this.emailCompare = this.basicData.emailAddress1
			if (!this.basicData.emailAddress1) {
				this.basicData.emailAddress1 = this.customer.userIdentifierExternalSystem || ""
			}
		}

		this.onlineCommunication = this.changeCommunicationPreferenceClicked || (this.customer.onlineCommunication || true)
	}

	display() {
		this.visible = true
		this.$emit("opened")
	}

	close() {
		this.visible = false
		this.$emit("closed")
	}

	async saveCommunicationPreference() {
		if (this.customer.externalIdentifier) {

			this.submitted = true

			let communicationChannel = "Email"
			let receivesMailing = true

			if (!this.onlineCommunication) {
				communicationChannel = "Mail"
				receivesMailing = false
			}

			this.communicationData.customerCommunicationTypeForPolicyDocumentsRef.externalIdentifier = communicationChannel
			this.communicationData.communicationTypeForClaimDocumentsRef.externalIdentifier = communicationChannel
			this.communicationData.receivesMailing = receivesMailing

			await customerApi.postCommunicationPreferences(this.customer.externalIdentifier, this.communicationData)

			if (this.changedEmail) {
				const basicDataNew = await customerApi.getCustomerBasicdata(this.customer.externalIdentifier)
				basicDataNew.emailAddress1 = this.basicData.emailAddress1

				await customerApi.postCustomerBasicData(this.customer.externalIdentifier, basicDataNew)
			}

			this.initChangeCommunicationPreference(false)
			this.modalTitle = "Bedankt!"
			this.communicationDataSaved = true
			this.submitted = false

			setTimeout(() => {
				this.communicationDataSaved = false
				this.setCommunicationPreferenceAcquired(true)
				this.setOnlineCommunication(this.onlineCommunication)
			}, 2500)
		}
	}
}
